/*************************
    Keycloak Override
**************************/
/* adjust the error-labels to the same size and look as the input fields */
#kc-form-login .error-label {
    margin: 0 auto;
    width: 70%;
    place-content:center;
}

.error-label {
    display: flex;
    background-color: #ffd9d9;
    color: #000000;
    font-family: BoschSansRegular;
    min-height: 2rem;
    align-items: center;
    font-size: 12px;
}

/* Put a warning icon before the error-message. */
.error-label::before {
    font-family: 'bosch-icon';
    font-size: 1.2rem;
    content: '\eb09';
    margin: auto 0.5rem;
    speak: none;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}


/*************************
    Custom Styling
**************************/
.login-rb, body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    margin: 0;
}

.login-pf-page .list-view-pf .list-group-item {
    border-bottom: 1px solid #ededed;
}

.login-pf-page .list-view-pf-description {
    width: 100%;
}

.login-pf-page .card-pf {
    margin-bottom: 10px;
}

.login-rb-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}

.socialProviders a {
    width: 90%;
    margin: 0 auto 0.5rem auto;
    display: flex;
}

.socialProviders a:hover, a:link, a:visited, a:active  {
    text-decoration: none;
}

.socialProviders img {
    max-height: 1.5rem;
	width: auto;
}

.socialProviders span {
    width: 100%;
    max-height: 44px;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.socialProviders img.withDisplayName {
    padding-right: 1rem;
}

.kc-login-tooltip {
    position: relative;
    display: inline-block;
}

.kc-login-tooltip .kc-tooltip-text {
    top: -3px;
    left: 160%;
    background-color: black;
    visibility: hidden;
    color: #fff;

    min-width: 130px;
    text-align: center;
    border-radius: 2px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.6);
    padding: 5px;

    position: absolute;
    opacity: 0;
    transition: opacity 0.5s;
}

/* Show tooltip */
.kc-login-tooltip:hover .kc-tooltip-text {
    visibility: visible;
    opacity: 0.7;
}

/* Space between input fields and submit button in OTP config */
#saveTOTPBtn {
    margin-top: 3rem;
}

/* Arrow for tooltip */
.kc-login-tooltip .kc-tooltip-text::after {
    content: " ";
    position: absolute;
    top: 15px;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}

/* mobile version */
@media only screen and (max-width: 767px) {
    .expandable {
        flex-direction: column;
        align-items: flex-start;
    }

    .card-rb h1 {
        line-height: 24px;
    }

    .login-rb-page {
        margin-top: 72px;
    }
}
